jQuery(document).ready(function () {

    var burger = document.querySelector('.burger');
    console.log(burger.style)
    //открывание меню
    $('.burger ').on('click', function () {
        $(this).toggleClass('active');
        $('.navigation').toggleClass('show');
    });

   
        $('.link').on('click', function() {
            if(screen.width < 768){
                $('.burger').toggleClass('active');
                $('.navigation').toggleClass('show');
            }
        })
    
 
    //
    scrollMenu();


    // flicity slider  
    $('.partners__slider').flickity({
        // options
        cellAlign: 'center',
        contain: true,
        freeScroll: true,
        wrapAround: true,
        // groupCells: true,
        // groupCells: 5,
        pageDots: false,
        arrowShape: {
            x0: 10,
            x1: 60, y1: 50,
            x2: 65, y2: 45,
            x3: 20
        }
    });
    
    // SCROLL
    $(".link").on("click", function (event) {
        var id  = $(this).attr('href');
        if ($(id).length != 0){
            $('body,html').animate({scrollTop: $(id).offset().top }, 1000);
        }
        return false;
    });
    // validation

    $("#form_modal").validate({

        rules:{
 
            form_tel:{
                required: true,
                digits: true,
             },
 
             form_email:{
                required: true,
                email:true,
             },

            form_textarea:{
                required: true,
            },
            form_check: {
                required:true,
            },
        },
 
        messages:{
 
            form_tel:{
                required:  "Поле не заполнено!",
                digits: "Введите номер!",
             },
 
             form_email:{
                 required: "Поле не заполнено!",
                 email:"Введите корректный e-maill!",
             },

            form_textarea:{
                required: "Поле не заполнено!",
            },

            form_check: {
                required:"V",
            },
 
        }
 
     });


    //  validate form footer 


    $("#f_form").validate({

        rules:{
 
            name:{
                required: true,
             },
 
            email:{
                required: true,
                email:true,
             },

            textarea:{
                required: true,
            },
          
        },
 
        messages:{
 
            name:{
                required:"Поле не заполнено!" ,
             },

             email:{
                 required: "Поле не заполнено!",
                 email:"Введите корректный e-maill!",
             },

            textarea:{
                required: "Поле не заполнено!",
            },
        }
 
     });


});







jQuery(document).scroll(function () {
    scrollMenu();
});

function scrollMenu() {
    if ($(window).scrollTop() > 1) {
        $('.b-header').addClass('plate');
    } else {
        $('.b-header').removeClass('plate');
    }
}



